/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";




// ion-searchbar{
//     --box-shadow:none !important;
// }

@font-face {
  font-family: Source;
  src: url('../src/assets/fonts/collegec.ttf') ;
}

@font-face {
  font-family: bubble;
  src: url('../src/assets/fonts/Bubblegum.ttf') ;
}
  body, span, button, h1, h2, h3, h4, h5, h6, p, ion-item, ion-title,ion-label, ion-input {
    font-family: 'Source' !important;
    font-size: 24px;
    }
 *{
    font-family: 'Source';
  }

  .valid{
    font-size: 18px;
  }


  .boton-cantidad{
    background-color: #252359;
    border: none;
    border-radius: 10%;
    height: 35px;
    width: 35px;
    color: white;
    font-size: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.boton-cantidad:hover{
    transform: scale(1.1);
}

.colorPrimary{
  color: #3880ff;
}

.imgPrimaryColor{
  filter: brightness(0) saturate(100%) invert(59%) sepia(82%) saturate(5602%) hue-rotate(207deg) brightness(104%) contrast(101%);
}

.expandButton{

  border-radius: 8px;
  text-align: center;
  color: white;
  font-weight: 800;
  font-size: 25px;
  width: 90%;
  margin: auto;
}


.enunciado{
  color: white;
 font-weight: 800;
 font-size: 19px !important;
}
.respuesta{
 font-size: 26px !important;
 margin-top: -18px;
}


.titleEnunciado{
    text-align: center;
    font-size: 28px;
    margin-top: 10px;
}


.img-item-carrito{
  border-radius: 12px;
  display: block;
  margin: auto;
}
.boton-wsp{
  position:fixed;
  height: 50px;
  width: 50px;
  bottom: 15px;
  right: 15px;
  z-index: 500;
}
